/*
 *
 */
function ImageCoverImage(args) {

    var $wrap = { },
        $imgs = { },
        $conf = { };



    function init(args) {
        if (checkArgs(args)) {
            $conf = getConfig(args);

            $imgs = {
                cvr: makeCoverImage(args.images[0]),
                bg: makeBackgroundImage(args.images[1]),
            };

            $wrap = {
                elem: args.target,
                pos: getElementPosition(args.target),
            };

            $wrap.elem.appendChild($imgs.cvr);
            $wrap.elem.appendChild($imgs.bg);

            ensurePosition(addEventListeners);

            return makePublicProperties();
        }

        else {
            return null;
        }
    }



    function checkArgs(args) {
        if (!args.hasOwnProperty('target')) {
            console.log("ImageCoverImage args object lacks a `target` element.");
            return null;
        }

        if ((!args.hasOwnProperty('images')) ||
            (!(args.images instanceof Array)) ||
            (!args.images.length == 2)) {
            console.log("ImageCoverImage args object needs two `images` in an array.");
            return null;
        }

        return true;
    }



    function getConfig(args) {
        var ret = { };

        if (args.hasOwnProperty('y')) {
            ret.borderAxis = 'y';
            ret.coverProp = 'height';
            ret.initBorder = args.y;
        }
        else {
            ret.borderAxis = 'x';
            ret.coverProp = 'width';
            ret.initBorder = (args.hasOwnProperty('x')) ? args.x : 50;
        }

        return ret;
    }



    function makeCoverImage(src) {
        var styles = {
            position: 'absolute',
            top: '0',
            left: '0',
            margin: '0',
            padding: '0',
            overflow: 'hidden',
            zIndex: '1',
            backgroundImage: "url('"+src+"')",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '0px 0px',
            backgroundSize: 'cover',
        };

        if ($conf.borderAxis == 'x') {
            styles.height = '100%';
            styles.width = $conf.initBorder + '%';
            styles.borderRight = '2px solid #ff0000';
        }
        else {
            styles.height = $conf.initBorder + '%';
            styles.width = '100%';
            styles.borderBottom = '2px solid #ff0000';
        }

        var wrap = document.createElement('div');

        for (var key in styles) {
            wrap.style[key] = styles[key];
        }

        return wrap;
    }



    function makeBackgroundImage(src) {
        var img = document.createElement('img'),
            styles = {
                display: 'block',
                position: 'relative',
                width: '100%',  // #TODO?
            };

        for (var key in styles) {
            img.style[key] = styles[key];
        }

        img.src = src;

        return img;
    }



    function ensurePosition(andThen) {
        function checkPosition() {
            var pos = getElementPosition($wrap.elem);

            if ((pos.x == $wrap.pos.x) &&
                (pos.y == $wrap.pos.y)) {
                clearInterval(intId);
                $wrap.pos = pos;
                andThen();
            }
            else {
                $wrap.pos = pos;
            }
        }

        var intId = window.setInterval(checkPosition, 50);
    }



    function addEventListeners() {
        $wrap.elem.addEventListener('mousedown', mouseDown);
        $wrap.elem.addEventListener('mouseenter', mouseOn);
        $wrap.elem.addEventListener('mouseleave', mouseOff);
        $wrap.elem.addEventListener('mousemove', mouseMove);

        $wrap.elem.addEventListener('touchstart', mouseDown);
        $wrap.elem.addEventListener('touchmove', mouseMove);
        $wrap.elem.addEventListener('touchend', mouseOff);

        window.addEventListener('resize', windowResize);
    }



    function removeEventListeners() {
        $wrap.elem.removeEventListener('mousedown', mouseDown);
        $wrap.elem.removeEventListener('mouseenter', mouseOn);
        $wrap.elem.removeEventListener('mouseleave', mouseOff);
        $wrap.elem.removeEventListener('mousemove', mouseMove);

        window.removeEventListener('resize', windowResize);
    }



    function mouseOn(evt) {
        // console.log("mouse is on target");
    }



    function mouseOff(evt) {
        handleCoverResize(evt);
    }



    function mouseMove(evt) {
        handleCoverResize(evt);
    }



    function mouseDown(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        handleCoverResize(evt);

        return false;
    }



    function handleCoverResize(evt) {
        var _evt = (evt.hasOwnProperty('changedTouches'))
            ? evt.changedTouches[0]
            : evt;

        setCoverImageSizeInPixels(
            getBorderPositionInPixels({x: _evt.clientX, y: _evt.clientY})[$conf.borderAxis]
        );
    }



    function windowResize(evt) {
        $wrap.pos = getElementPosition($wrap.elem);
    }



    function setCoverImageSizeInPixels(pos) {
        $imgs.cvr.style[$conf.coverProp] = pos + 'px';
    }



    function setCoverImageSizeAsPercent(pos) {
        $imgs.cvr.style[$conf.coverProp] = pos + '%';
    }



    function resetCoverImageSize() {
        setCoverImageSizeAsPercent($conf.initBorder);
    }



    function getBorderPositionInPixels(coords) {
        return {
            x: (coords.x - $wrap.pos.x),
            y: (coords.y - $wrap.pos.y),
        };
    }



    function getBorderPositionAsPercent(coords) {
        var dims = getDimensions($wrap.elem),
            pctX = Math.round(((coords.x - $wrap.pos.x) / dims.w) * 100);
            pctY = Math.round(((coords.y - $wrap.pos.y) / dims.h) * 100);

        if (pctX > 100) {pctX = 100;}
        else if (pctX < 0) {pctX = 0;}

        if (pctY > 100) {pctY = 100;}
        else if (pctY < 0) {pctY = 0;}

        return {
            x: pctX,
            y: pctY,
        };
    }



    function getDimensions(elem) {
        return {
            w: elem.offsetWidth,
            h: elem.offsetHeight
        };
    }



    function getElementPosition(elem) {
        var curtop,
            curleft = curtop = 0;

        if (elem.offsetParent) {
            do {
			    curleft += elem.offsetLeft;
			    curtop += elem.offsetTop;
            } while (elem = elem.offsetParent);
        }

	    return {
            x: curleft,
            y: curtop
        };
    }



    function makePublicProperties() {
        return {
            stop: removeEventListeners,
            start: addEventListeners,
            reset: resetCoverImageSize,
        };
    }



    //////////////////////////////



    // This needs to stay down here.
    return init(args);
}
