/*
 * STRATA
 *
 * Strata is a module for creating the visual effect of sections of
 * a page acting as layers. It does this by manipulating each layer
 * element's `position` and `z-index` CSS properties in relation to
 * the page's current scroll position.
 *
 *
 * USAGE
 *
 * Strata.init({
 *   body: document.getElementById('strata-body'),
 *   foot: document.getElementById('strata-foot'),
 *   layers: document.getElementsByClassName('strata-layer'),
 *   onSwitch: function (elem) {
 *     doSomethingWithElem(elem);
 *   },
 * });
 *
 *
 * DEPENDENCIES
 *
 * None.
 *
 *
 * DETAILS
 *
 * Initialize Strata with an object containing a `body` key (the
 * value of which should be the element containing the `layers and
 * which will be treated differently than the `foot`), a `foot` key
 * (the value of which should be the page's footer element, which
 * will receive special treatment according to the viewport's size),
 * and a `layers` key (the value of which should be an array-like
 * containing the elements that will be treated as layers). If an
 * `onSwitch` key is part of the initialization object, its value
 * should be a function that takes an element.
 *
 * Basically, Strata calculates the height of the `layers` when they
 * have `relative` positions, sets the height of the `body` element
 * (or the `document.body` if the viewport is shorter than the `foot`
 * element) to that sum, and then changes their positions to `fixed`
 * while retaining their distances from the window origin. While the
 * user scrolls, the current scroll position is checked againt those
 * distances. When the current scroll position rolls over a layer's
 * recorded distance, its position switches back to `relative`.
 *
 * The layers' distances are recalculated and re-recorded when the
 * window is resized.
 *
 * If the viewport is shorter than the `foot` element, it will be
 * treated as a layer, meaning that it can be scrolled up just as
 * the other layers can. Otherwise, the `foot` will be fixed to the
 * bottom of the page, and a margin equal to its height will be set
 * on the `body` element, thereby allowing the `foot` to be seen /
 * revealed but not to be scrolled.
 * 
 */
var Strata = (function() {

    // The body element will need to be sized.
    var $body = null,
        // The foot element.
        $foot = null,
        // The layer elements.
        $layers = [ ],
        // The stack. This will be the layers and maybe the foot.
        $stack = [ ],
        // This might be a function.
        $onSwitch = null,
        // Debounce the resize handler for better performance.
        _resizeHandler = debounce(recalcRefs, 500);



    function init(conf) {
        if (!objectHasKeys(conf, ['body', 'foot', 'layers'])) {
            console.error("STRATA ERROR: init object requires these keys: body, foot, layers.");
            return false;
        }

        if (conf.hasOwnProperty('onSwitch')) {
            $onSwitch = conf.onSwitch;
        }

        $body = conf.body;
        $foot = conf.foot;

        if (conf.layers instanceof Array) {
            $layers = conf.layers;
        }
        else {
            var layers = [ ];
            for (var o = 0, m = conf.layers.length; o < m; o++) {
                layers.push(conf.layers[o]);
            }
            $layers = layers;
        }

        addEventListeners();

        makeStack(checkScroll, 100);
    }



    function makeStack(and_then, delay) {
        setTimeout(
            function () {
                var _c = checkStack();
                $stack = _c.elems;

                styleStack();

                checkHeight(_c.height, and_then, delay);
            },
            100
        );
    }



    function checkStack() {
        var stack = [ ];

        for (var o = 0, m = $layers.length; o < m; o++) {
            stack.push($layers[o]);
        }

        // If the footer is taller than the window, it needs to be in the stack.
        if (getViewportDims().height < $foot.scrollHeight) {
            stack.push($foot);
        }

        return {
            elems: stack,
            height: setStackPositions(stack),
        };
    }



    function setStackPositions(elems) {
        if (typeof elems == 'undefined') {
            elems = $stack;
        }

        for (var o = 0, m = elems.length, _h = 0; o < m; o++) {
            elems[o].strataPos = _h;
            _h += elems[o].scrollHeight;
        }

        return _h;
    }



    function styleStack() {
        if ($stack.length == $layers.length) {
            $foot.style.removeProperty('top');
            $foot.style.position = 'fixed';
            $foot.style.bottom = '0px';
            $foot.style.zIndex = '0';
        }

        // If the $foot is in the $stack it will be styled below.
        else {
            $foot.style.removeProperty('bottom');
        }

        for (var o = 0, m = $stack.length, z = $stack.length; o < m; o++, z--) {
            $stack[o].style.top = '0px';
            $stack[o].style.left = '0px';
            $stack[o].style.zIndex = z;
        }
    }



    function clearStackStyle(elem) {
        elem.style.removeProperty('position');
        elem.style.removeProperty('bottom');
        elem.style.removeProperty('left');
        elem.style.removeProperty('top');
    }



    function checkHeight(ref_height, when_matches, delay) {
        setTimeout(
            function () {
                var matches = true,
                    height = 0;

                // out:
                // for (var o = 0, m = $stack.length; o < m; o++) {
                //     if ($stack[o].strataPos !== height) {
                //         matches = false;
                //         break out;
                //     }

                //     height += $stack[o].scrollHeight;
                // }

                height = document.getElementById('strata-body').offsetHeight;

                // if ((!matches) || (height !== ref_height)) {
                //     checkHeight(setStackPositions(), when_matches, delay);
                // }
                // else {
                    fixBodyHeights(height);
                    when_matches();
                // }
            },
            delay
        );
    }



    function fixBodyHeights(height) {
        if ($stack.length == $layers.length) {
            document.body.style.removeProperty('height');
            $body.style.marginBottom = $foot.scrollHeight + 'px';
            $body.style.height = height + 'px';
        }
        else {
            $body.style.removeProperty('height');
            $body.style.removeProperty('margin-bottom');
            document.body.style.height = height + 'px';
        }
    }



    function resetHeights() {
        $body.style.removeProperty('height');
        document.body.style.removeProperty('height');

        // The $foot might be part of the $stack.
        for (var o = 0, m = $stack.length; o < m; o++) {
            clearStackStyle($stack[o]);
        }

        // So if it's not, clear it here.
        if ($stack.length == $layers.length) {
            clearStackStyle($foot);
        }
    }



    function checkScroll(evt) {
        var doc = document.documentElement,
            pos = ((window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)),
            switch_on = false;

        for (var o = 0, m = $stack.length; o < m; o++) {
            if (pos >= $stack[o].strataPos) {
                if ($stack[o].style.position == 'fixed') {
                    switch_on = $stack[o];
                }
                $stack[o].style.position = 'relative';
            }
            else {
                if ((o > 0) && ($stack[o].style.position == 'relative')) {
                    switch_on = $stack[(o - 1)];
                }
                $stack[o].style.position = 'fixed';
            }
        }

        if ($onSwitch && switch_on) {
            $onSwitch(switch_on);
        }
    }



    function recalcRefs(evt) {
        if (!iOS) {
            resetHeights();
            makeStack(checkScroll, 100);
        }
    }



    function handleRecalc() {
        recalcRefs();
    }



    function addEventListeners() {
        window.addEventListener('scroll', checkScroll);
        window.addEventListener('resize', _resizeHandler);
    }



    function removeEventListeners() {
        window.removeEventListener('scroll', checkScroll);
        window.removeEventListener('resize', _resizeHandler);
    }



    function objectHasKeys(obj, keys) {
        for (var o = 0, m = keys.length; o < m; o++) {
            if (!obj.hasOwnProperty(keys[o])) {
                return false;
            }
        }

        return true;
    }



    function getViewportDims() {
        var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

        return {
            width: w,
            height: h,
        }
    }



    function iOS() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    }



    // via https://davidwalsh.name/function-debounce
    function debounce(func, wait, immediate) {
        var timeout;

        return function() {
            var context = this,
                args = arguments;

            var later = function() {
                timeout = null;

                if (!immediate) {
                    func.apply(context, args);
                }
            };

            var callNow = (immediate && !timeout);

            clearTimeout(timeout);

            timeout = setTimeout(later, wait);

            if (callNow) {
                func.apply(context, args);
            }
        };
    }





    /*
     * Public.
     */
    return {
        init: init,
        recalc: handleRecalc,
    };

})();
