/*
 * SPINNER
 *
 * This is a module for creating a spinner. Because users don't like
 * to wait while they're waiting.
 *
 * It does very little. The animations are all configured in CSS.
 *
 *
 * USAGE
 *
 * To create a new spinner with the default configuration:
 * var spinner_elem = Spinner.new();
 *
 * To dismiss that spinner:
 * Spinner.kill(spinner_elem);
 *
 * To put the spinner in an element, not the `document.body`:
 * var spinner_elem = Spinner.new(document.getElementById(ELEM-ID));
 *
 * To use config settings other than the default, do something like:
 * var spinner_elem = Spinner.new(
 *   null,
 *   {box: {css_class: 'different-box',
 *          attribute_sequence: ['pt1', 'pt2', 'pt3']} }
 * );
 *
 * To dismiss all spinners using the `different-box` class:
 * Spinner.killall({box: {css_class: 'different-box'}});
 *
 *
 * DEPENDENCIES
 *
 * None, though the `mergeObjects` function is identical to the
 * `Utils.sieve` function. So if you use Utils.js, you can remove
 * this redundancy by replacing those calls.
 *
 *
 * DETAILS
 *
 * There are at least three parts to the spinner:
 * - one background element
 * - one element that contains the spinner
 * - one or more elements that spin
 *
 * In the config object these are keyed on `background`, `box`, and
 * `parts`, respectively. Each key in those objects is explained in
 * the `getDefaultConf` function.
 *
 * This module creates those elements with the tagnames and CSS IDs
 * and classes specified in the configuration object, then sets an
 * interval running that cycles through the `box` element's 
 * `attribute_sequence`. You can specify an attribute other than the
 * `class` by changing the `box`'s `interval_attribute`.
 *
 * So after every number of milliseconds specified in the config's
 * `delay`, the `box`'s `interval_attribute` will change to the next
 * value in its `attribute_sequence`.
 *
 * That changing of attributes creates the spinner's various states.
 * The details of each state -- colors, content, animations, etc --
 * are specified in your CSS. For best effect, the `delay` time
 * should match the CSS transition's time.
 *
 * You can set per-spinner configuration by passing a subset of the
 * config object to `new` as the second parameter. You can make the
 * config persistent by sending that same object to `setConf`. And
 * to reset to the default, call `setConf` with no parameters.
 *
 * The ID returned by `window.setInterval` is stored as an attribute
 * on the element returned by `Spinner.new`. You can set the name of
 * that attribute in `conf.background.interval_attribute`.
 *
 *
 */

var Spinner = (function () {


    // This is the default configuration object. Custom config
    // objects can be any subset of this but they must have the same
    // structure. So `{box: {css_class: 'whatever'}}` will work, but
    // `{css_class: 'whatever'}` will not.
    function getDefaultConf() {
        return {

            // This is the overlay element. It will be appended
            // either to the element passed as the first parameter
            // `Spinner.new` or to the `document.body`.
            background: {
                // This is the tag name to use.
                tag_name: 'div',

                // The element's ID. If none is given, then none
                // will be used.
                id: null,

                // The CSS class.
                css_class: 'spinner-bg',

                // The attribute name to use for saving the
                // window's interval ID.
                interval_attribute: 'spinnerid'
            },

            // This is the element that contains the spinning parts.
            box: {
                // The tag name to use.
                tag_name: 'div',

                // The element's ID.
                id: null,

                // The CSS class.
                css_class: 'spinner-wrap',

                // The name of the attribute to iterate on. The
                // values in the `attribute_sequence` will be
                // appended to this and changed -- other values in
                // this attribute will not be changed.
                interval_attribute: 'class',

                // The sequence to cycle through.
                attribute_sequence: ['a', 'b', 'c', 'd']
            },

            // These are the spinning parts.
            parts: {
                // The tag name to use.
                tag_name: 'div',

                // The CSS class common to every part.
                css_class_common: 'spinner-part',

                // This sequence specifies both the number of
                // spinning parts and the names of their classes.
                // They will be created in this order.
                css_class_sequence: ['white', 'red', 'blue', 'white'],

                // This is the number of times you want that
                // sequence to be repeated.
                sequence_multiple: 1
            },

            // This is the number of milliseconds between each
            // iteration through the box's attribute sequence.
            delay: 500,

            // For console messages.
            log: false
        }
    }


    var conf = getDefaultConf(),
        keep = { };



    function makeNewConf(conf_obj) {
        if (conf.log) {
            console.log("Pulling new config settings from:");
            console.log(conf_obj);
        }

        var new_conf = mergeObjects(conf, conf_obj);

        if (conf.log) {
            console.log("New config settings:");
            console.log(new_conf);
        }

        return new_conf;
    }



    /*
     * If you use Utils.js, you can replace calls to this with calls
     * to `Utils.sieve`.
     */
    function mergeObjects(obj1, obj2) {
        if (conf.log) {
            console.log("Merging objects:");
            console.log(obj1);
            console.log("and:");
            console.log(obj2);
        }

        var new_obj = { };

        for (var key in obj1) {
            if (obj1.hasOwnProperty(key)) {
                if (obj2.hasOwnProperty(key)) {
                    if (obj1[key] === null) {
                        new_obj[key] = obj2[key];
                    }

                    else if ((obj1[key].constructor == Object) &&
                             (obj2[key].constructor == Object)) {
                        new_obj[key] = mergeObjects(obj1[key], obj2[key]);
                    }

                    else {
                        new_obj[key] = obj2[key];
                    }
                }

                else {
                    new_obj[key] = obj1[key];
                }
            }
        }

        return new_obj;
    }



    function makeSpinnerWithTempConf(temp_conf, target) {
        if (conf.log) {
            console.log("Making spinner with temporary configuration.");
        }

        var bk_conf = conf;
        conf = makeNewConf(temp_conf);
        var elem = makeSpinnerAppear(target);
        conf = bk_conf;

        return elem;
    }



    function makeSpinnerAppear(target) {
        if (conf.log) {
            console.log("Starting new spinner creation procedure.");
        }

        var elem = makeSpinnerElement();

        if (target) {
            if (conf.log) {
                console.log("Appending spinner to given element.");
            }

            target.appendChild(elem);
        }

        else {
            if (conf.log) {
                console.log("Appending spinner to document body.");
            }

            document.body.appendChild(elem);
        }

        var interval_id = makeSpinnerSpin(elem);
        elem.setAttribute(conf.background.interval_attribute, interval_id);
        keep[interval_id] = {elem: elem, conf: conf};

        if (conf.log) {
            console.log("Done with spinner creation. Interval ID: " + interval_id);
        }

        return elem;
    }



    function makeSpinnerElement() {
        if (conf.log) {
            console.log("Creating new spinner element.");
        }

        var bg = document.createElement(conf.background.tag_name);
        if (conf.background.css_class) {bg.className = conf.background.css_class;}
        if (conf.background.id) {bg.id = conf.background.id;}

        var box = document.createElement(conf.box.tag_name);
        if (conf.box.css_class) {box.className = conf.box.css_class;}
        if (conf.box.id) {box.id = conf.box.id;}

        var parts_classes = conf.parts.css_class_sequence,
            n_max = (parts_classes.length - 1),
            n = 0;

        for (var o = 0, m = (parts_classes.length * conf.parts.sequence_multiple); o < m; o++) {
            var part = document.createElement(conf.parts.tag_name),
                classes = [ ];

            if (conf.parts.css_class_common) {
                classes.push(conf.parts.css_class_common);
            }

            if (n > n_max) {n = 0;}
            classes.push(parts_classes[n]);
            n += 1;

            part.className = classes.join(' ');

            box.appendChild(part);
        }

        bg.appendChild(box);

        return bg;
    }



    function makeSpinnerSpin(elem) {
        if (conf.log) {
            console.log("Setting spinner interval.");
        }

        var attribute = conf.box.interval_attribute,
            sequence = conf.box.attribute_sequence,
            max_pos = (sequence.length - 1),
            curr_val = sequence[curr_pos],
            curr_pos = 0,
            box = elem.firstChild;

        function setSpinAttribute(old_val, new_val) {
            var old_classes;

            if (conf.log) {
                console.log("Updating spinner state to: " + new_val);
            }

            if (old_classes = box.getAttribute(attribute)) {
                old_classes = old_classes.split(' ');
                var new_classes = [ ];

                for (var o = 0, m = old_classes.length; o < m; o++) {
                    if ((old_classes[o].length > 0) && (old_classes[o] !== old_val)) {
                        new_classes.push(old_classes[o]);
                    }
                }

                box.setAttribute(attribute, (new_classes.concat(new_val).join(' ')));
            }
            else {
                box.setAttribute(attribute, new_val);
            }
        }

        function spinSpinner() {
            if (curr_pos > max_pos) {curr_pos = 0;}
            var new_val = sequence[curr_pos];
            setSpinAttribute(curr_val, new_val);
            curr_val = new_val;
            curr_pos += 1;
        }

        spinSpinner();

        return window.setInterval(spinSpinner, conf.delay);
    }



    function removeSpinner(elem) {
        var interval_id = elem.getAttribute(conf.background.interval_attribute);

        if (conf.log) {
            console.log("Removing spinner with interval ID: " + interval_id);
        }

        elem.parentNode.removeChild(elem);
        window.clearInterval(interval_id);

        delete keep[interval_id];
    }



    function removeSpinnersByConf(conf_query) {
        if (conf.log) {
            console.log("Removing spinners that match this config:");
            console.log(conf_query);
        }

        for (var key in keep) {
            if (keep.hasOwnProperty(key)) {
                var entry = keep[key],
                    _conf = entry.conf,
                    _elem = entry.elem;

                if (JSON.stringify(_conf) == JSON.stringify(mergeObjects(_conf, conf_query))) {
                    removeSpinner(_elem);
                }
            }
        }
    }





    /*
     * Public methods.
     */

    return {
        new: function(target, temp_conf) {
            if (typeof temp_conf == 'object') {
                return makeSpinnerWithTempConf(temp_conf, target);
            }
            else {
                return makeSpinnerAppear(target);
            }
        },

        kill: function(elem) {
            removeSpinner(elem);
        },

        killall: function(conf_query) {
            removeSpinnersByConf(conf_query);
        },

        setConf: function(conf_obj) {
            conf = (typeof conf_obj == 'object')
                ? makeNewConf(conf_obj)
                : getDefaultConf();
            return conf; 
        }
    }

})();
