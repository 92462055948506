/*
 *
 */
var ElementFilter = (function () {

    var $conf = { },
        $dict = { },
        $elems = [ ],
        $list = null;



    function init(conf) {
        var null_conf = nullConfObj();
        $conf = mergeObjects(null_conf, conf);

        for (var key in null_conf) {
            if ($conf[key] === null) {
                console.log("Error initializing Element Filter: lacking value for `"+key+"`.");
                return false;
            }
        }

        // Build the match data structure.
        var matches = cullMatchValues($conf.check.getValues, $conf.check.values, $conf.check.wrapClass);
        $elems = matches.elems;
        $dict = matches.dict;

        // console.log("MATCHES:");
        // console.log(matches);

        // Build the filter list element.
        var vals = [ ];
        for (var key in $dict) {
            vals.push(key);
        }
        $list = buildMatchList(vals, $conf.clear, $conf.list);
        $conf.list.parent.appendChild($list.wrap);
        vals = null;

        // Add event listeners to the list-toggle button.
        $conf.list.button.addEventListener('click', showList);

        // Display the current filter in the toggle button.
        if ($conf.current == $conf.clear) {
            clearFilter();
        }
        else {
            filterSelected($dict, $conf.current);
        }
    }



    function nullConfObj() {
        return {
            check: {
                values: null,
                getValues: null,
                wrapClass: null,
                matchClass: null,
            },
            list: {
                parent: null,
                wrapClass: null,
                dispClass: null,
                listClass: null,
                itemClass: null,
                activeClass: null,
                button: null,
            },
            clear: null,
            current: null,
            callback: false,  // This allows it to be optional.
        };
    }



    function mergeObjects(obj1, obj2) {
        var merged = { };

        for (var key in obj1) {
            if (obj2.hasOwnProperty(key)) {
                if ((obj2[key]) && (obj2[key].constructor == Object)) {
                    merged[key] = mergeObjects(obj1[key], obj2[key]);
                }
                else {
                    merged[key] = obj2[key];
                }
            }
            else {
                merged[key] = obj1[key];
            }
        }

        return merged;
    }



    function getParentByClass(ref_elem, match_class) {
        var elem = ref_elem;

        while (!Clattr.has(elem, match_class)) {
            elem = elem.parentNode;
        }

        return elem;
    }



    // cullMatchValues loops through the given `val_elems`, passes
    // each to `getValues`, and builds an object keyed on those match
    // values. The values under each key will be an array of elements
    // that match that key.
    function cullMatchValues(getVals, val_elems, match_class) {
        var dict = { },
            wraps = [ ];

        function addToDict(key, elem) {
            if (typeof dict[key] == 'undefined') {
                dict[key] = [ ];
            }

            dict[key].push(elem);
        }


        for (var o = 0, m = val_elems.length; o < m; o++) {
            var wrap = getParentByClass(val_elems[o], match_class);
            wraps.push(wrap);

            var vals = getVals(val_elems[o]);
            for (var i = 0, n = vals.length; i < n; i++) {
                addToDict(vals[i].trim(), wrap);
            }
        }

        return {
            dict: dict,
            elems: wraps,
        };
    }



    function buildMatchList(vals, clear, conf) {
        var list = document.createElement('ul');
        list.className = conf.listClass;

        var items = [ ];


        function makeListItem(disp_txt, on_click) {
            var item = document.createElement('li');
            item.className = conf.itemClass;
            item.innerHTML = disp_txt;
            item.addEventListener('click', on_click);
            list.appendChild(item);
            items.push(item);
        }


        makeListItem(clear, handleFilterClear);

        for (var o = 0, m = vals.length; o < m; o++) {
            makeListItem(vals[o], handleFilterSelection);
        }

        var wrap = document.createElement('div');
        wrap.className = conf.wrapClass;
        wrap.appendChild(list);

        return {
            wrap: wrap,
            // list: list,
            items: items,
        };
    }



    function handleFilterClear(evt) {
        clearFilter();
        callCallback();
    }



    function handleFilterSelection(evt) {
        var elem = getEventTarget(evt),
            filt = elem.innerHTML;

        filterSelected($dict, filt);
        callCallback();
    }



    function clearFilter() {
        showAllElems();

        markMatchActive($list.items, $conf.clear, $conf.list.activeClass);
        $conf.list.button.innerHTML = $conf.clear;

        hideList();
    }



    function filterSelected(dict, filt) {
        filterElements(dict, filt, $conf.check.matchClass);
        markMatchActive($list.items, filt, $conf.list.activeClass);

        $conf.list.button.innerHTML = filt;

        hideList();
    }



    function getEventTarget(evt) {
        var elem = evt.target || evt.srcElement;

	    if (elem.nodeType == 3) {
		    elem = elem.parentNode;
        }

        return elem;
    }



    function showAllElems() {
        Clattr.add($elems, $conf.check.matchClass);
    }



    function filterElements(dict, filt, match_class) {
        for (var key in dict) {
            if (key == filt) {
                Clattr.add($dict[key], match_class);
            }
            else {
                Clattr.remove($dict[key], match_class);
            }
        }
    }



    function hideList(evt) {
        Clattr.remove($list.wrap, $conf.list.dispClass);
    }



    function showList(evt) {
        Clattr.add($list.wrap, $conf.list.dispClass);
    }



    function markMatchActive(list, filt, actv_class) {
        var offs = [ ],
            on = null;

        for (var o = 0, m = list.length; o < m; o++) {
            if (list[o].innerHTML == filt) {
                on = list[o];
            }
            else {
                offs.push(list[o]);
            }
        }

        Clattr.remove(offs, actv_class);
        Clattr.add(on, actv_class);
    }



    function callCallback(func) {
        if (($conf.callback) && (typeof $conf.callback == 'function')) {
            $conf.callback();
        }
    }





    /*
     * Public.
     */
    return {
        init: init,
        list: true,
        select: true,
        current: true,
        show: true,
        hide: true,
    };

})();
