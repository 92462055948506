function swapNavColorOnElem(elem) {
    var img_file = document.getElementById('nav-logo-img'),
        list_items = document.getElementById('nav-wrap').getElementsByClassName('nav-list-link'),
        burger_lines = document.getElementById('hamburger').getElementsByClassName('nav-list-line'),
        swap_out = 'white',
        swap_in = 'black';

    if (Clattr.has(elem, 'white-on-black')) {
        swap_out = 'black';
        swap_in = 'white';
    }

    // console.log("Swapping " + swap_out + " for " + swap_in);

    Clattr.replace(
        img_file,
        img_file.getAttribute('src'),
        img_file.getAttribute('src').replace(swap_out, swap_in),
        'src'
    );

    Clattr.replace(
        list_items,
        swap_out + '-text',
        swap_in + '-text'
    );

    Clattr.replace(
        burger_lines,
        'nav-list-line-' + swap_out,
        'nav-list-line-' + swap_in
    );
}



window.addEventListener(
    'load',
    function () {
        // For the sectional layer effect.
        Strata.init({
            body: document.getElementById('strata-body'),
            foot: document.getElementById('strata-foot'),
            layers: document.getElementsByClassName('strata-layer'),
            onSwitch: function (elem) {
                swapNavColorOnElem(elem);
            },
        });


        // For the burger.
        var modal = document.getElementById('modal'),
            button = document.getElementById('hamburger'),
            exit = document.getElementsByClassName('close-button')[0],
            selection = document.getElementsByClassName('close');

        //bring modal up
        button.onclick = function(){
            modal.style.display = "block";
        };
        //end of open modal

        //close modal when 'x' is clicked
        exit.onclick = function(){
            modal.style.display = "none";
        };
        //end of close modal

        //close the modal when nav is clicked
        selection.onclick = function() {
            modal.style.display = "none";
        };
        //end of close modal


        // Remove the spinner.
        function checkLoad(videos) {
            var loaded = true,
                cloak;

            for (var o = 0, m = videos.length; o < m; o++) {
                if (videos[o].readyState !== 4) {
                    loaded = false;
                }
            }

            if (loaded) {
                if (cloak = document.getElementById('loading-cloak')) {
                    document.body.scrollTop = 0;
                    Spinner.kill(loading_cloak);
                    cloak.parentNode.removeChild(cloak);
                }
            }
            else {
                setTimeout((function () {checkLoad(videos);}), 200);
            }
        }
        var videos = document.getElementsByTagName('video');
        checkLoad(videos);

    },
    false
);
